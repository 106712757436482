<template>
  <div class="v-reports-show">
    <Progress variant="odd" with-overlay="background" />
  </div>
</template>

<script>
import { functions } from '@/helpers';

export default {
  name: 'VReportsShow',
  async created() {
    const uri = await this.fetchReportSignedURI();

    window.location.replace(uri);
  },
  methods: {
    async fetchReportSignedURI() {
      const { reportId } = this.$route.params;

      return functions.fetchFileSignedURI({
        filename: `reports/${reportId}`,
        bucket: 'process-bucket-cmdic',
      });
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

.v-reports-show
  height: 100%

  +d-flex-r(center, center)
</style>
